section {
    padding: 8rem 0; }
.pd-top {
    padding: 0 !important; }
.red {
    color: $red !important; }
.hidden-title {
    font-size: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0  !important; }
.dark-layout {
    border-radius: 0 0 50px 50px;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(transparent, rgba(0,0,0,.8));
        z-index: 10;
        border-radius: 0 0 50px 50px; } }
section.hero {
    padding-bottom: 0;
    position: relative; }

section.hero.inner {
    margin-top: 0;
    border-radius: 0 0 50px 50px;
    height: 44rem;
    background: no-repeat center center;
    background-size: cover;
    background-position: top; }
.hero_heading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10rem;
    color: $white;
    z-index: 10;
    h1 {
        width: 30rem; } }
body.overlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    z-index: 10000;
    opacity: 0.5; }

div.cash-express-breadcrumb {
    margin-top: 3rem;
    margin-bottom: 1rem;
    li {
        display: inline-block;
        a {
            color: $grey-light;
            font-weight: lighter;
            &.active {
                font-weight: normal; } }
        &:not(:last-child):after {
            content: ' -';
            color: $grey-light; } } }

ul.cash-express-ul, article ul {
    li {
        position: relative;
        background: $light;
        padding: 1.3rem 0;
        margin: .8rem 0;
        padding-left: 3rem;
        margin-left: 1rem;
        border-radius: 15px;
        &:before {
            content: '';
            display: block;
            width: 2rem;
            height: 2rem;
            background: $primary;
            position: absolute;
            top: 50%;
            left: -1rem;
            transform: translateY(-50%);
            border-radius: 3rem; }
        p {
            margin: 0; } } }
.cash-express-ul {
    background: unset !important;
    margin-left: 0 !important;
    padding-left: 0 !important; }
section {
    .cta {
        position: relative;
        background: #E3E3E3;
        border-radius: 15px;
        overflow: hidden;
        .content {
            padding: 6rem;
            position: relative;
            overflow: hidden;
            & > * {
                position: relative; }
            @media screen and (max-width: 576px) {
                padding: 2rem; } }
        &:before {
            content: url('../img/illustrations/cta-shape-left.svg');
            position: absolute;
            left: 0;
            bottom: -1rem;
            @media screen and (max-width: 576px) {
                display: none; } }
        &:after {
            content: url('../img/illustrations/cta-shape-right.svg');
            position: absolute;
            right: -2rem;
            top: -1rem;
            @media screen and (max-width: 576px) {
                display: none; } } } }
#accordion h4 {
    border-radius: 15px;
    i {
        line-height: .7; } }
.article {
    display: block;
    margin: 3rem 0;
    & > div {
        position: relative;
        border-radius: 15px; }
    .info {
        padding: 1rem;
        color: $black;
        position: absolute;
        width: 70%;
        bottom: -2rem;
        transition: all .3s;
        background-color: #fff;
        border-radius: 0 15px 15px 0; }
    &:hover {
        .info {
            background: $primary;
            color: $white;
            transition: all .3s; } } }

aside.cash-express-aside {
    ul li {
        margin-bottom: .5rem;
        &:first-child {
            a {
                border-radius: 10px 10px 0 0; } }
        &:last-child {
            a {
                border-radius: 0 0 10px 10px; } }
        a {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem 2rem;
            background: $light;
            color: $black;
            transition: all .1s;
            &:hover,
            &.active {
                color: $primary;
                text-decoration: none;
                font-weight: bold;
                transition: all .1s;
                padding-right: 1.5rem;
                svg path {
                    fill: $primary; } } } } }
.element {
    &:before {
        content: url('../img/illustrations/shape-benefit-red.svg');
        position: absolute;
        right: 0;
        top: 8%; } }
@media screen and (max-width: 576px) {
    .accordion-container {
        padding: 2rem 0 8rem 0; } }
div.accordion {
    h4 {
        padding: 2rem;
        background-color: $light;
        cursor: pointer;
        &:focus {
            outline: none; }
        i {
            float: right;
            color: $primary;
            font-weight: bold;
            transition: all .3s; }
        &.ui-state-active {
            i {
                transform: rotate(180deg);
                transition: all .3s; } } }
    .ui-accordion-content {
        border-radius: 15px;
        margin-top: -2.2rem;
        padding: 0 0.6rem;
        height: auto !important; } }
.contacts-map {
    #map-contacts {
        width: 105%;
        height: 400px;
        border-radius: 15px;
        @media screen and (max-width: 768px) {
            width: 100% !important;
            height: 400px !important; } } }
.special-mr {
    margin-right: 1rem;
    @media screen and (max-width: 768px) {
        margin-right: 0 !important; } }
.faq-title {
    margin: 6rem 0; }
.accordion {
    p {
        font-weight: initial !important; } }

body::-webkit-scrollbar {
    width: 0.6rem; }
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2); }
body::-webkit-scrollbar-thumb {
    background-color: $primary; }
