.become_our_agent-layout {
    section.hero.inner {
      height: 0rem !important;
      padding-top: 45rem;
      @media screen and (max-width: 768px) {
        padding-top: 35rem; }
      @media screen and (max-width: 576px) {
        padding-top: 25rem; }
      @media screen and (max-width: 356px) {
        padding-top: 22rem; } }
    .hero_heading {
      bottom: 0 !important; } }
div.cash-form {
    background: $light;
    padding: 3rem;
    z-index: 99;
    border-radius: 15px;
    h3 {
        margin-bottom: 3rem; }
    input,
    textarea {
        width: 100%; }
    &.inner {
        position: relative;
        margin-top: -10rem;
        @media screen and (max-width: 576px) {
            margin-top: 0 !important; } } }
.modal-content {
    background-color: transparent !important;
    border: none !important; }
.modal-open .sticky {
    z-index: 99 !important; }
.fixed-close-button {
    background: url('../img/illustrations/close-button.svg') no-repeat center;
    background-size: cover;
    width: 2rem;
    height: 2rem;
    position: absolute;
    display: block;
    top: 5%;
    right: 10%;
    z-index: 1000;
    opacity: 1 !important; }
.fixed-button-modal {
    background-color: #F3F5F7;
    display: flex;
    align-items: center;
    padding: 0 0 0 2rem;
    height: 10rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    p {
        width: 40%;
        font-size: 2.4rem;
        font-weight: bold;
        margin-right: 4rem; }
    button {
        margin-right: 2rem !important; }
    @media screen and (max-width: 376px) {
        p {
            font-size: 2rem; }
        button {
            padding: 1.3rem 3rem !important; } }
    @media screen and (max-width: 320px) {
        p {
            font-size: 1.8rem; }
        button {
            padding: 1.2rem 2rem !important; } } }

// Feedback Section

.testimonial {
    background: #F3F5F7;
    position: relative;
    padding: 2rem;
    margin: 2rem;
    border-radius: 15px;
    transition: 0.3s all;
    p {
        font-size: 16px !important;
        color: $black;
        padding: 2rem 0; }
    h5 {
        color: black;
        text-transform: text-capitalize;
        font-size: 20px;
        margin-top: 8px; }
    span {
        color: #848484; }
    &:hover {
        outline: 1px solid #D12128;
        transition: 0.3s all;
        transform: scale(1.1); } }
.quote {
    position: absolute;
    right: 0rem;
    top: -2rem;
    z-index: 3;
    color: #292626; }

// Siema Controllers
.testimonial-controller {
    bottom: -1rem;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    li {
        padding: 0 0.3rem; } }

@media screen and ( max-width:  526px) {
    .siema-testimonials {
        margin-bottom: 2rem !important; }
    .testimonial-controller {
        flex-wrap: wrap; } }
.next {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 0; }
.prev {
    position: absolute;
    left: 0;
    cursor: pointer;
    top: 50%; }

